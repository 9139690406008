<template>
    <div style="height:100%;">
        <div class="chart" ref="chart" :style="{ width: '100%', height: height }"></div>
    </div>
</template>

<script>

export default {
    name: 'pieEcharts',

    data() {
        return {
            chart: null
        };
    },
    props: {
        height: {
            type: String,
            default: '100%'
        },
        dataList: {
            type: Array
        }
    },
    mounted() {
        this.echarts()
    },

    methods: {
        nowSize(val, initWidth = 1920) {
            //当前视口宽度
            let nowClientWidth = document.documentElement.clientWidth;
            return val * (nowClientWidth / initWidth);
        },
        echarts() {
            this.chart = this.$echarts.init(this.$refs.chart); // 初始化echarts
            var option = {
                xAxis: {
                    type: 'category',
                    data: ['淄博市临淄区养老中心第二康复养老院','淄博市康寿护理养生院','临淄区中医医院（第一康复养老院）','淄博健寿苑养老服务有限公司金山健南苑养老分公司','淄博健寿苑养老服务有限公司','临淄区金岭清源老年健康服务中心','淄博中康爱邻里养老服务有限公司临淄分公司','淄博市临淄区夕阳红桓公台养老中心','淄博健寿苑养老服务有限公司敬仲健馨苑养老分公司','淄博市临淄区春雨慈光养老院'],
                    axisLabel: {
                        textStyle: {
                            color: '#33A0AF', // x轴字体颜色
                            fontSize: this.nowSize(10),
                        },
                        rotate: this.nowSize(30), // 设置x轴标签旋转角度
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#33A0AF' // y轴字体颜色
                        },

                    }
                },
                series: [
                    {
                        data: [315, 227, 206, 200, 155, 133, 119,117,108,106],
                        type: 'bar',
                        barWidth: this.nowSize(20),
                        itemStyle: {
                            normal: {
                                barBorderRadius: [0, 3, 0, 0],// 柱子圆角
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: "#FF766F" },
                                    { offset: 1, color: "rgba(255,118,111,0.00)" },
                                ]),
                            }
                        }
                    }
                ],
                grid: {
                    top: this.nowSize(10),   // 上边距
                    bottom: this.nowSize(70), // 下边距
                    left: this.nowSize(50)
                },
            };
            this.chart.setOption(option); // 设置对应配置项
            // 当窗口大小改变 echarts重置大小
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        }
    },
};
</script>

<style lang="scss" scoped></style>