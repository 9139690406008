<template>
    <div class="organization">
        <div class="title">
            机构养老
        </div>
        <div style="height:calc(15% -  1.74rem);padding:3px" class="num">
            <!-- 机构数量 -->
            <titleWord :word="word1"></titleWord>
            <div class="numBox">
                <div class="numContent" v-for='(item, index) in jgslList' :key=index @click="jgsl(index)">
                    <div :class="jgslIndex == index ? 'name' : 'name2'">
                        {{ item.name }}
                    </div>
                    <div :class="jgslIndex == index ? 'num' : 'num2'">
                        {{ item.num }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 入住率 -->
        <div style="height:22%;" class="rzl">
            <titleWord :word="word2"></titleWord>
            <div class="rzlContent">
                <pie1 :dataList="data"></pie1>
            </div>
        </div>
        <!-- top10 -->
        <div style="height:22%;" class="q10">
            <titleWord :word="word3"></titleWord>
            <div class="q10Content">
                <columnar></columnar>
            </div>
        </div>
        <div style="height:21%;" class="rzl">
            <titleWord :word="word4"></titleWord>
            <div class="rzlContent">
                <pie2 :dataList="data2"></pie2>
            </div>
        </div>
        <div style="height:18%;" class="rzl">
            <titleWord :word="word5"></titleWord>
            <div class="rzlContent">
                <check></check>
            </div>
        </div>
    </div>
</template>

<script>
import { titleWord } from "@/components";
import pie1 from '../echarts/pie1.vue'
import columnar from '../echarts/columnar.vue'
import pie2 from '../echarts/pie2.vue'
import check from '../list/check.vue'
export default {
    name: 'organization',
    components: { titleWord, pie1, columnar, pie2, check },
    data() {
        return {
            // 机构数量
            word1: '机构数量',
            jgslList: [{
                num: 1,
                name: '公办民营'
            }, {
                num: 6,
                name: '公办公营'
            }, {
                num: 10,
                name: '民办民营'
            },],
            jgslIndex: 0,
            // 入住率
            word2: '入住率',
            data: [{
                value: 993,
                name: '已入住'
            }, {
                value: 1975,
                name: '床位总数'
            },],
            data2: [{
                value: 363,
                name: '自理'
            }, {
                value: 361,
                name: '失能'
            }, {
                value: 310,
                name: '半失能'
            },],
            // 10
            word3: '养老机构入驻老人数排序TOP10',
            word4: '机构入住老人分析',
            word5: '最新入住'
        };
    },

    mounted() {

    },

    methods: {
        jgsl(e) {
            this.jgslIndex = e
        }
    },
};
</script>

<style lang="scss" scoped>
.organization {
    height: calc(100% - 26px);
    width: calc(100% - 26px);
    background: url('~@/assets/content/box_bg1.png');
    background-size: 100% 100%;
    padding: 13px;
    z-index: 99;

    .title {
        height: 1.74rem;
        width: 20.43rem;
        background: url('~@/assets/content/titleB.png');
        background-size: 100% 100%;
        font-size: 1rem;
        line-height: 1.74rem;
        font-family: DOUYU, DOUYU-常规体;
        font-weight: normal;
        text-align: left;
        color: #ffffff;
        padding-left: 13px;
    }

    // 机构数量
    .num {
        .numBox {
            height: calc(100% - 1.43rem);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .numContent {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                cursor: pointer;

                .name {
                    font-size: 0.7rem;
                    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: #ffc000;
                }

                .name2 {
                    font-size: 0.7rem;
                    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: #ffffff;
                }

                .num {
                    width: 5.65rem;
                    height: 2.17rem;
                    border: 0.09rem solid;
                    font-size: 1.3rem;
                    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
                    font-weight: 700;
                    text-align: center;
                    color: #ffc000;
                    background-image: url("~@/assets/content/jgsl1.png");
                    background-size: 100% 100%;
                    line-height: 2.17rem;
                }

                .num2 {
                    width: 5.65rem;
                    height: 2.17rem;
                    border: 0.09rem solid;
                    font-size: 1.3rem;
                    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
                    font-weight: 700;
                    text-align: center;
                    color: #32beff;
                    background-image: url("~@/assets/content/jgsl2.png");
                    background-size: 100% 100%;
                    line-height: 2.17rem;
                }
            }
        }
    }

    // 入住率
    .rzl {
        .rzlContent {
            height: calc(100% - 1.43rem);
            width: 100%;
            padding: 5px;
        }
    }

    .q10 {
        height: 100%;

        .q10Content {
            height: calc(100% - 1.43rem);
            width: 100%;
            padding: 5px;
        }
    }
}
</style>