import request from '@/utils/request'

export function getTypeDate(data) {
  return request({
    url: '/api/dataCount/getTypeDate',
    method: 'post',
    data
  })
}
export function getDataInfo(data) {
    return request({
      url: '/api/oldUser/getDataInfo.json',
      method: 'get',
      params:data
    })
  }
  export function saveData(data) {
    return request({
      url: '/api/healthRecord/saveData.json',
      method: 'post',
      data
    })
  }
  export function oldUser(data) {
    return request({
      url: '/api/oldUser/saveData.json',
      method: 'post',
      data
    })
  }
  export function getDataByUserId(data) {
    return request({
      url: '/api/supporter/getDataByUserId.json',
      method: 'post',
      data
    })
  }
  export function supporterSave(data) {
    return request({
      url: '/api/supporter/saveData.json',
      method: 'post',
      data
    })
  }
  export function deleteData(data) {
    return request({
      url: '/api/oldUser/deleteData.json',
      method: 'post',
      data
    })
  }
  export function buffet(data) {
    return request({
      url: '/api/buffet/getList.json',
      method: 'post',
      data
    })
  }
  export function buffetCapitalGetDataList(data) {
    return request({
      url: '/api/buffetCapital/getDataList.json',
      method: 'post',
      data
    })
  }
  export function buffetCapitalGetcollect(data) {
    return request({
      url: '/api/buffetCapital/getcollect.json',
      method: 'post',
      data
    })
  }
  export function buffetDishesSaveData(data) {
    return request({
      url: '/api/buffetDishes/saveData.json',
      method: 'post',
      data
    })
  }
  export function buffetDishesGetDataList(data) {
    return request({
      url: '/api/buffetDishes/getDataList.json',
      method: 'post',
      data
    })
  }
  export function buffetDishesTypeGetDataList(data) {
    return request({
      url: '/api/buffetDishesType/getDataList.json',
      method: 'post',
      data
    })
  }
  export function buffetDishesTypeSaveData(data) {
    return request({
      url: '/api/buffetDishesType/saveData.json',
      method: 'post',
      data
    })
  }
  export function buffetDishesTypeGetList(data) {
    return request({
      url: '/api/buffetDishesType/getList.json',
      method: 'post',
      data
    })
  }
  export function buffetDishesSaveData2(data) {
    return request({
      url: '/api/buffetDishes/saveData.json',
      method: 'post',
      data
    })
  }
  export function buffetDishesDeleteData(data) {
    return request({
      url: '/api/buffetDishes/deleteData.json',
      method: 'post',
      data
    })
  }
  export function buffetOrderGetDataList(data) {
    return request({
      url: '/api/buffetOrder/getDataList.json',
      method: 'post',
      data
    })
  }
  export function buffetOrderGetcollect(data) {
    return request({
      url: '/api/buffetOrder/getcollect.json',
      method: 'post',
      data
    })
  }
  export function buffetGetDataList(data) {
    return request({
      url: '/api/buffet/getDataList.json',
      method: 'post',
      data
    })
  }
  export function buffetSaveData(data) {
    return request({
      url: '/api/buffet/saveData.json',
      method: 'post',
      data
    })
  }
  export function buffetDataCountGetMonthCount(data) {
    return request({
      url: '/api/buffetDataCount/getMonthCount',
      method: 'post',
      data
    })
  }

  export function buffetDataCountGetCount(data) {
    return request({
      url: '/api/buffetDataCount/getCount',
      method: 'post',
      data
    })
  }
  