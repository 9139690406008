import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from 'element-ui' 
import 'element-ui/lib/theme-chalk/index.css';
import './permission' 
import '@/styles/default.scss'  
import comFun from './comfun'
import './utils/directives'; // 弹窗拖拽
import Print from 'vue-print-nb'
import * as echarts from 'echarts'
import '@/utils/flexible.js'
import {fontchart}  from '@/utils/echarts.js'
import dataV from '@jiaminghi/data-view'
import MAP from "./map";
import axios from 'axios'
import VueAMap from 'vue-amap';
Vue.use(dataV)
Vue.prototype.$fontchart = fontchart
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;
Vue.prototype.$comFun = comFun
Vue.prototype.$axios = axios
Vue.use(comFun)
Vue.use(Element) 
Vue.use(Print)
Vue.use(MAP);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");