<template>
  <div>
    <div class="hl-header">
      <!-- <div class="logo"></div> -->
      <div class="text">
        <div class="word">
          <i :class="icoISshow ? 'el-icon-s-fold' : 'el-icon-s-unfold'" style="color:#595959;cursor: pointer;"
            @click="toggleSideBar"></i>
        </div>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link" style="display: flex;align-items: center;">
            <div style="width:30px;height:30px;border: 2px solid #eeeeee; text-align: center; line-height: 30px;
border-radius: 50%;"><i class="el-icon-user-solid" style="width: 30px;
height: 22px;
color: #37c8f7;"></i></div><span class="hl-userName">{{
  userName }}</span>
            <i class="el-icon-arrow-down el-icon-caret-bottom" style="margin-top: 10px;color: #595959;"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/home/personal">
              <el-dropdown-item>个人设置</el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click.native="loginOut()">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  components: {},
  data() {
    return {
      routerClass: 'jgzx',
      icoISshow: true
    }
  },
  computed: {
    userName() {
      return this.$store.getters.user.userName
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    async loginOut() {
      this.$confirm('尊敬的用户您好,即将退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.$store.dispatch('user/logout')
        this.$store.dispatch('tagsView/delAllViews')
        this.$router.push(`/login`)
        // 记录之前浏览过的页面
        // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      }).catch(() => {

      });
    },
    fristClick(e) {
      this.routerClass = e.key
      this.resetSetItem('routrKey', JSON.stringify(e.key))
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
      this.icoISshow = !this.icoISshow
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/theme.scss";

.el-dropdown {
  display: inline-block;
  position: relative;
  position: absolute;
  right: $headerHeight * 0.4;
  font-size: $headerHeight * 0.4;
  color: #fff;
}

.el-dropdown .el-icon-caret-bottom {
  font-size: 1rem;
  top: -0.5rem;
  position: relative;
}

.routerB {
  margin-right: 10px;
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.20);
  text-align: center;
  line-height: 5rem;
  transition: all 0.3s ease-in-out;
  transform: translateX(10px);
}

.bottom_x {
  width: 100%;
  height: 5px;
  background: #b8ff43;
  position: absolute;
  bottom: 0;
}
</style>
