import * as lrxx from '@/api/lrxx'
const actions = {
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
      lrxx.getDataList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
}
export default {
  namespaced: true,
  actions
}