import * as sysRole from '@/api/sysRole'
const actions = {
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      sysRole.saveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
      sysRole.getDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getAllList() {
    return new Promise((resolve, reject) => {
      sysRole.getAllList().then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDataInfo({}, parm) {
    return new Promise((resolve, reject) => {
      sysRole.getDataInfo(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      sysRole.deleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  actions
}