<template>
    <div style="height:calc(100% - 4rem)">
        <div class="chart" ref="chart" :style="{ width: '100%', height: '100%' }"></div>
    </div>
</template>

<script>

export default {
    name: 'pieEcharts',

    data() {
        return {
            chart: null
        };
    },
    props: {
        dataList: {
            type: Array
        }
    },
    mounted() {
        this.echarts()
    },

    methods: {
        nowSize(val, initWidth = 1920) {
            //当前视口宽度
            let nowClientWidth = document.documentElement.clientWidth;
            return val * (nowClientWidth / initWidth);
        },
        echarts() {
            this.chart = this.$echarts.init(this.$refs.chart); // 初始化echarts
            var option = {
                xAxis: {
                    type: 'category',
                    data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
                    axisLabel: {
                        textStyle: {
                            fontSize: this.nowSize(10), // 设置字体大小
                            color: '#33a0af' // 设置字体颜色
                        }
                    },
                    axisLine: {
                        show: false
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            fontSize: this.nowSize(10), // 设置字体大小
                            color: '#33a0af' // 设置字体颜色
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#0a5a65' // 设置横线颜色
                        }
                    }
                },
                series: [
                    {
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                        type: 'line',

                        smooth: true,
                        itemStyle: {
                            // 找到 lineStyle 属性
                            normal: {
                                // 设置 color 属性为你想要的颜色值
                                color: '#36DA83'
                            }
                        },
                        areaStyle: {
                            color: 'rgba(54,218,131,0.50)' // 设置填充色为蓝色半透明
                        }
                    }
                ],
                grid: {
                    left: '10%', // 左边距
                    right: '10%', // 右边距
                    bottom: '18%', // 底部边距
                    top: '10%' // 顶部边距
                },
            };
            this.chart.setOption(option); // 设置对应配置项
            // 当窗口大小改变 echarts重置大小
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        }
    },
};
</script>

<style lang="scss" scoped></style>