<template>
  <section class="app-main">
    <transition name="fade-transform"
                mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    //keep-alive详细解释
    //https://blog.csdn.net/qq_37548296/article/details/110798890
    // <!--cachedViews中的数组元素为组件的name值，name匹配的组件将被缓存，所以需要路由中配置name，要跟组件中的name相同 -->
    cachedViews () {
      return this.$store.getters.cachedViews
    },
    key () {
      return this.$route.path
    }
  },
  mounted () {
  },
}
</script>


