<template>
  <div :class="classObj" class="hl-layout-wrapper-skc">
    <div class="hl-header-wrapper-skc">
      <div class="av-container" :style="{ width: !isClose ? '100%' : '55px' }">
        <div
          style="display: flex; align-items: center; width: 100%"
          v-if="!isClose"
        >
          <el-image
            style="width: 55px; height: 55px"
            :src="logo"
            fit="fit"
          ></el-image>
          <div style="margin-left: 20px;">齐智养老</div>
        </div>
        <div
          v-else
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
          "
        >
          <el-image
            style="width: 35px; height: 35px"
            :src="logo"
            fit="fit"
          ></el-image>
        </div>
      </div>
      <Sidebar></Sidebar>
      <!-- <div class="indentation">
        <i class="icolor"
           @click="toggleSideBar"
           :class="!isClose?'el-icon-caret-left':'el-icon-caret-right'"></i>
      </div> -->
    </div>
    <div
      class="hl-container-skc"
      :style="{ width: !isClose ? 'calc(100% - 210px)' : 'calc(100% - 55px)' }"
    >
      <HlHeader></HlHeader>
      <div class="hl-content">
        <TagsView></TagsView>
        <AppMain></AppMain>
      </div>
    </div>
  </div>
</template>

<script>
import { AppMain, Sidebar, TagsView, HlHeader } from "./components";
import { mapState } from "vuex";
import logo from "../assets/default/logo.png";
export default {
  name: "Layout",
  data() {
    return {
      logo: logo,
    };
  },
  components: {
    AppMain,
    Sidebar,
    TagsView,
    HlHeader,
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
    }),
    classObj() {
      return {
        hideSidebar: this.sidebar.opened,
      };
    },
    isClose() {
      return this.sidebar.opened;
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.hl-layout-wrapper-skc {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.av-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background: #005aaa;
  color: white;
  font-size: 1.3rem;
  height: 5rem;
}

.hl-header-wrapper-skc {
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
}

.hl-container-skc {
  display: flex;
  height: 100%;
  flex-direction: column;
}
</style>
