<template>
    <div class="living">
        <div class="title">
            居家养老
        </div>
        <div class="content">
            <!-- 物联 -->
            <div class="wlsb">
                <titleWord :word="word1"></titleWord>
                <div class="card">
                    <div class="titles">设备总数</div>
                    <card :val="val"></card>
                </div>
                <div class="yuanPie">
                    <yuanPie v-for="(item, index) in pieIndex" :key="index" :value="item.value" :color="item.color"
                        :text="item.text"></yuanPie>
                </div>

            </div>
            <!-- 居家上门 -->
            <div class="jjsm">
                <titleWord :word="word2"></titleWord>
                <div class="jjsmContent">
                    <div class="smBox" v-for="(item, index) in jjList" :key="index">
                        <div class="jjIco" :style="{
                            'background-image': 'url(' + item.ico + ')',
                            'background-repeat': 'no-repeat',
                        }" style="height: 3.13rem; width: 3.13rem; background-size: 100% 100%">

                        </div>
                        <div class="numTitle">
                            <div class="num">
                                {{ item.num }}
                            </div>
                            <div class=titles>
                                {{ item.title }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { titleWord, card, yuanPie } from "@/components";
export default {
    name: 'living',
    components: { titleWord, card, yuanPie },
    data() {
        return {
            word1: '物联设备状态',
            word2: '居家上门服务',
            val: '000595',
            pieIndex: [
                {
                    value: '585',
                    color: '#00FFFF',
                    text: '在线设备'
                },
                {
                    value: '10',
                    color: '#50ABFF',
                    text: '离线设备'
                },
                {
                    value: '0',
                    color: '#E4BC32',
                    text: '故障设备'
                },
                {
                    value: '2',
                    color: '#FF5A4D',
                    text: '低电量设备'
                },
            ],
            jjList: [
                {
                    ico: require("@/assets/content/ic_fwdx.png"),
                    num: '317',
                    title: '总服务对象'
                },
                {
                    ico: require("@/assets/content/ic_zxhy.png"),
                    num: '539',
                    title: '完成上门工单'
                },
                {
                    ico: require("@/assets/content/ic_wcgd.png"),
                    num: '19962',
                    title: '总服务小时'
                },
                {
                    ico: require("@/assets/content/ic_wcxm.png"),
                    num: '317',
                    title: '完成回访'
                },
                {
                    ico: require("@/assets/content/ic_fwsj.png"),
                    num: '317',
                    title: '服务总数'
                },
                {
                    ico: require("@/assets/content/ic_wchf.png"),
                    num: '317',
                    title: '总服务对象'
                },
            ]

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.living {
    height: calc(100% - 26px);
    width: calc(100% - 26px);
    background: url('~@/assets/content/bakbottom2.pic.jpg');
    background-size: 100% 100%;
    padding: 13px;
    z-index: 99;

    .title {
        height: 1.74rem;
        width: 20.43rem;
        background: url('~@/assets/content/titleB.png');
        background-size: 100% 100%;
        font-size: 1rem;
        line-height: 1.74rem;
        font-family: DOUYU, DOUYU-常规体;
        font-weight: normal;
        text-align: left;
        color: #ffffff;
        padding-left: 13px;
    }

    .content {
        display: flex;
        height: calc(100% - 1.74rem);

        .wlsb {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .card {
                display: flex;
                align-items: center;
                margin: 0.5rem 0 0 0.5rem;

                >.titles {
                    font-size: 1.5rem;
                    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
                    font-weight: 700;
                    text-align: center;
                    color: #ffffff;
                    height: 3.4rem;
                    line-height: 3.75rem;
                    width: 11.125rem;
                    background: url('~@/assets/content/zs.pic.jpg');
                    background-size: 100% 100%;
                }

            }

            .yuanPie {
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }

        .jjsm {
            flex: 1;

            .jjsmContent {
                height: calc(100% - 4rem);
                margin: 0.5rem 0 0 0.5rem;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: end;

                .smBox {
                    width: 8.5rem;
                    height: 4rem;
                    background: rgba(0, 90, 170, 0.20);
                    border: 1px solid #32beff;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    padding: 0 0.5rem 0 0.5rem;

                    .numTitle {
                        width: calc(100% - 4.13rem);

                        .num {
                            font-size: 1.5rem;
                            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
                            font-weight: 700;
                            text-align: left;
                            color: #ffffff
                        }

                        .titles {
                            font-size: 0.9rem;
                            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
                            font-weight: 400;
                            text-align: left;
                            color: #7ee4f5;
                            zoom: 0.7;
                        }
                    }
                }
            }
        }
    }
}
</style>