import request from '@/utils/request'

export function saveData(data) {
  return request({
    url: '/api/sysModule/saveData.json',
    method: 'post',
    data
  })
}

export function moduleTree(data) {
  return request({
    url: '/api/sysModule/moduleTree.json',
    method: 'post',
    data
  })
}

export function deleteData(data) {
  return request({
    url: '/api/sysModule/deleteData.json',
    method: 'post',
    data
  })
}
export function livingProjectdeleteData(data) {
  return request({
    url: '/api/livingProject/deleteData.json',
    method: 'post',
    data
  })
}
export function livingProjectgetListByContract(data) {
  return request({
    url: '/api/orderService/getListByContract.json',
    method: 'post',
    data
  })
}
// 助餐
export function getListByLevel(data) {
  return request({
    url: '/api/subsidyStandard/getListByLevel.json',
    method: 'post',
    data
  })
}
export function subsidyStandardsaveData(data) {
  return request({
    url: '/api/subsidyStandard/saveData.json',
    method: 'post',
    data
  })
}
export function subsidyStandarddeleteData(data) {
  return request({
    url: '/api/subsidyStandard/deleteData.json',
    method: 'post',
    data
  })
}
export function getDataTree(data) {
  return request({
    url: "/api/area/getTreeByCode.json",
    method: "post",
    params: data,
  })
}
export function areagetTree(data) {
  return request({
    url: '/api/area/getTree.json',
    method: 'post',
    data
  })
}
export function buffetCapitalgetDataList(data) {
  return request({
    url: '/api/buffetCapital/getDataList.json',
    method: 'post',
    data
  })
}
export function buffetCapitalgetcollect(data) {
  return request({
    url: '/api/buffetCapital/getcollect.json',
    method: 'post',
    data
  })
}