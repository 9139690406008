//动态注册模版组件
const mulbusinesss = require.context('@/views/DP/component', true, /\.vue$/)
const modules = mulbusinesss.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = mulbusinesss(modulePath)
    modules[moduleName] = value.default
    return modules
}, {})
const pageArr = []
const businessArray = [];
const pageChildren = {}
Object.keys(modules).forEach(k => {
    pageChildren[k] = {
        ref: modules[k].name,
        component: modules[k], //组件
    }
    pageArr.push(pageChildren[k])
    businessArray.push(pageChildren[k]);
})
// 该模块应用与main(主页的一切mock数据存放)
const state = {
    // 业务功能模块组件
    businessArray: businessArray,
    //模块组件
    pageArr: pageArr,
    //模块配置
    pageConfig: {
        /**  
         * @param code:编码,与pageArr中的name对应,获取每个组件的配置项
         */
    },
    //布局配置
    /**  
     *@param code:编码,与pageArr中的name对应,让组件进入对应容器
     */
     layOutConfig: [
        {
            title: "机构养老",
            code: "organization",
            options: {
                style: {
                    'position': 'absolute',
                    'height':'calc(100% - 1rem)',
                    'left':'1rem',
                    'width':'25.62rem',
                }
            }
        },
        {
            title: "老年总体数据",
            code: "allNum",
            //   code: "",

            options: {
                style: {
                  
                    'position': 'absolute',
                    'height':'10.23rem',
                    'left':'1rem',
                    'right':'1rem',
                    'width':'calc(100% - 27.62rem * 2)',
                    'margin':'auto'
                }
            }
        },
        {
            title: "社区养老",
            code: "community",
            options: {
                style: {
             
                    'position': 'absolute',
                    'height':'calc(100% - 1rem)',
                    'right':'1rem',
                    'width':'25.62rem',
                }
            }
        }, 
        {
            title: "居家养老",
            code: "living",
            options: {
                style: {
              
                    'position': 'absolute',
                    'height':'16.87rem',
                    'left':'1rem',
                    'right':'1rem',
                    'bottom':'1rem',
                    'width':'calc(100% - 27.62rem * 2)',
                    'margin':'auto'
                }
            }
        }
    ],
}
const mutations = {}
const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}