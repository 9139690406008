import request from "@/utils/request";

/**
 * 网格管理
 * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7184} data
 * @returns
 */
// 获取网格树
export function getTree(data) {
  return request({
    url: "/api/area/getTreeByCode.json",
    method: "post",
    params: data,
  });
}
// 新增/修改
export function saveTree(data) {
  return request({
    url: "/api/area/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delTree(data) {
  return request({
    url: "/api/area/deleteData.json",
    method: "post",
    data,
  });
}

/**
 * 老人管理
 * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7205} data
 * @returns
 */
// 分页列表
export function getElderlyInfoList(data) {
  return request({
    url: "/api/elderlyInfo/getDataList.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveElderlyInfo(data) {
  return request({
    url: "/api/elderlyInfo/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delElderlyInfo(data) {
  return request({
    url: "/api/elderlyInfo/deleteData.json",
    method: "post",
    data,
  });
}

/**
 * 助残站点
 * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7212} data
 * @returns
 */
// 分页列表
export function getMealSiteList(data) {
  return request({
    url: "/api/mealSite/getDataList.json",
    method: "post",
    data,
  });
}
// 不分页列表
export function getMealSiteAllList(data) {
  return request({
    url: "/api/mealSite/getAllData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveMealSite(data) {
  return request({
    url: "/api/mealSite/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delMealSite(data) {
  return request({
    url: "/api/mealSite/deleteData.json",
    method: "post",
    data,
  });
}

/**
 * 站点菜单
 * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7219} data
 * @returns
 */
// 分页列表
export function getMealMenuList(data) {
  return request({
    url: "/api/mealMenu/getDataList.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveMealMenu(data) {
  return request({
    url: "/api/mealMenu/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delMealMenu(data) {
  return request({
    url: "/api/mealMenu/deleteData.json",
    method: "post",
    data,
  });
}

/**
 * 助餐记录
 * @param {http://192.168.3.247:3000/project/2614/interface/api/cat_7226} data
 * @returns
 */
// 分页列表
export function getMealRecordList(data) {
  return request({
    url: "/api/mealRecord/getDataList.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveMealRecord(data) {
  return request({
    url: "/api/mealRecord/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delMealRecord(data) {
  return request({
    url: "/api/mealRecord/deleteData.json",
    method: "post",
    data,
  });
}
// 流水
export function getMoney(data) {
  return request({
    url: "/api/mealRecord/getMoney.json",
    method: "post",
    data,
  });
}

/**
 * 高龄补贴
 * @param {https://console-docs.apipost.cn/preview/49c3e31c8121b05d/14bd6fec5d565f15?target_id=c019b052-0b2d-415e-b421-7cbe232f76bb} data
 * @returns
 */
// 分页列表
export function getOldAgeAllowanceList(data) {
  return request({
    url: "/api/elderlyInfo/getOldAgeAllowanceList.json",
    method: "post",
    data,
  });
}
// 发放补贴操作
export function saveOldAgeAllowance(data) {
  return request({
    url: "/api/oldAgeAllowance/saveData.json",
    method: "post",
    data,
  });
}
// 补贴历史记录
export function getOldDataList(data) {
  return request({
    url: "/api/oldAgeAllowance/getDataList.json",
    method: "post",
    data,
  });
}
// 充值记录
export function getRechargeList(data) {
  return request({
    url: "/api/rechargeRecord/getDataList.json",
    method: "post",
    data,
  });
}
// 充值历史记录
export function getDataListAll(data) {
  return request({
    url: "/api/rechargeRecord/getDataListAll.json",
    method: "post",
    data,
  });
}
// 充值记录查询总金额
export function getRechMoney(data) {
  return request({
    url: "/api/rechargeRecord/getMoney.json",
    method: "post",
    data,
  });
}