import request from '@/utils/request'

export function getDataList(data) {
  return request({
    url: '/api/organization/getDataList.json',
    method: 'post',
    data
  })
}
export function deleteData(data) {
    return request({
      url: '/api/organization/deleteData.json',
      method: 'post',
      data
    })
  }
  export function saveData(data) {
    return request({
      url: '/api/organization/saveData.json',
      method: 'post',
      data
    })
  }
  export function orderServiceGetDataList(data) {
    return request({
      url: '/api/orderService/getDataList.json',
      method: 'post',
      data
    })
  }
  export function orderServicesaveData(data) {
    return request({
      url: '/api/orderService/saveData.json',
      method: 'post',
      data
    })
  }