<template>
    <div class="allNum">
        <div class="title">
            老年总体数据
        </div>
        <div class="content">
            <div class="old">
                <div class="box_old" v-for="(item, index) in old" :key="index">
                    <div class="sui">
                        {{ item.sui }}岁
                    </div>
                    <div class="bf">
                        {{ item.bf }}
                    </div>
                    <div class="sum">
                        {{ item.sum }}人
                    </div>
                </div>
            </div>
            <div style="
height: 5.63rem;
border-left: 0.06rem solid #00ffff;">

            </div>
            <div class="gender">
                <div class="box_old" v-for="(item, index) in gender" :key="index">
                    <div class="sui">
                        {{ item.sui }}
                    </div>
                    <div class="bf">
                        {{ item.bf }}
                    </div>
                    <div class="sum">
                        {{ item.sum }}人
                    </div>
                </div>
            </div>
            <div style="
height: 5.63rem;
border-left: 0.06rem solid #00ffff;">

            </div>
            <div class="aging">
                <div class="box_old" v-for="(item, index) in aging" :key="index">
                    <div class="sui">
                        {{ item.sui }}
                    </div>
                    <div class="bf">
                        {{ item.bf }}(严重)
                    </div>
                    <div class="sum">
                        {{ item.sum }}人
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'allNum',

    data() {
        return {
            old: [
                {
                    sui: '60-80',
                    bf: '83.25%',
                    sum: '106665'
                },
                {
                    sui: '80-89',
                    bf: '14.74%',
                    sum: '18875'
                },
                {
                    sui: '90-99',
                    bf: '1.97%',
                    sum: '2528'
                },
                {
                    sui: '≥100',
                    bf: '0.04%',
                    sum: '48'
                },
            ],
            gender: [
                {
                    sui: '男性',
                    bf: '47%',
                    sum: '60214'
                },
                {
                    sui: '女性',
                    bf: '53%',
                    sum: '97901'
                },
            ],
            aging: [
                {
                    sui: '老龄化程度',
                    bf: '26.48%',
                    sum: '62239'
                },
            ],
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.allNum {
    height: 100%;
    width: calc(100% - 26px);
    background: url('~@/assets/content/box_bg2.png');
    background-size: 100% 100%;
    padding: 13px;
    z-index: 99;

    .title {
        height: 1.74rem;
        width: 20.43rem;
        background: url('~@/assets/content/titleB.png');
        background-size: 100% 100%;
        font-size: 1rem;
        line-height: 1.74rem;
        font-family: DOUYU, DOUYU-常规体;
        font-weight: normal;
        text-align: left;
        color: #ffffff;
        padding-left: 13px;
    }

    .content {
        height: calc(100% - 1.74rem);
        width: 100%;
        display: flex;
        align-items: center;

        .old {
            flex: 4;
            display: flex;
            justify-content: space-around;
        }

        .gender {
            flex: 2;
            display: flex;
            justify-content: space-around;
        }

        .aging {
            flex: 1;
            display: flex;
            justify-content: space-around;
        }

        .box_old {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .sui {
                font-size: 1rem;
                font-family: Source Han Sans CN, Source Han Sans CN-Regular;
                font-weight: 400;
                text-align: center;
                color: #fff;
            }

            .bf {
                font-size: 1.13rem;
                font-family: DOUYU, DOUYU-常规体;
                font-weight: normal;
                text-align: center;
                color: #fff;
            }

            .sum {
                width: 7.25rem;
                height: 2.75rem;
                background-image: url("~@/assets/content/xz.png");
                background-size: 100% 100%;
                font-size: 0.88rem;
                font-family: Source Han Sans CN, Source Han Sans CN-Bold;
                font-weight: 700;
                text-align: center;
                color: #fff;
                line-height: 1.56rem;
            }
        }
    }
}
</style>