<template>
    <div style="height:calc(100% - 1rem);  overflow: hidden;">
        <vueSeamlessScroll :data="listData" class="seamless-warp" :class-option="classOption">

            <div v-for="(item, index) in listData" :key="index" class="box">
                <div class="left">
                    <div class="name">
                        {{ item.name }}
                    </div>
                    <div class="jg">
                        服务机构：{{ item.jg }}
                    </div>
                </div>
                <div class="right">
                    <div class="time">
                        {{ item.time }}
                    </div>
                    <div class="dz">
                        {{ item.dz }}
                    </div>
                </div>
            </div>

        </vueSeamlessScroll>
    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
    name: 'ChanghuCheck',
    components: {
        vueSeamlessScroll,
    },

    // 设置计算属性
    computed: {
        classOption() {
            return {
                step: 0.2, // 数值越大速度滚动越快
                limitMoveNum: this.listLenght, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                isSingleRemUnit: true
            };
        },
    },
    data() {
        return {
            listData: [
                {
                    name: '刘*生',
                    jg: '淄博市康寿护理养生院',
                    dz: '临淄区胜炼10号',
                    time: '2023-12-25'
                },
                {
                    name: '王*生',
                    jg: '临淄区金岭清源老年健康服务中心',
                    dz: '淄博市临淄区金岭回族镇金岭三村清田苑小区内',
                    time: '2023-10-25'
                },
                {
                    name: '赵*生',
                    jg: '临淄区金岭清源老年健康服务中心',
                    dz: '淄博市临淄区金岭回族镇金岭三村清田苑小区内',
                    time: '2023-10-25'
                },
                {
                    name: '李*雪',
                    jg: '淄博市康寿护理养生院',
                    time: '2023-08-25'
                },
                {
                    name: '户*生',
                    dz: '临淄区胜炼10号',
                    time: '2023-08-25'
                },

            ],
            listLenght: ''
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.seamless-warp {
    height: 100%;
}

.box {
    width: 100%;
    height: 4rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #2e6757;

    .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
            font-size: 1.13rem;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: left;
            color: #32beff;
        }

        .jg {
            font-size: 0.68rem;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: left;
            color: #32beff;
            /* 禁止文本换行 */
            overflow: hidden;
            /* 隐藏超出容器的部分 */
            text-overflow: ellipsis;
            width: 100%;
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;

        .time {
            font-size: 0.68rem;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: right;
            color: #32beff;
            ;
            margin-bottom: calc(1.13rem - 0.78rem);
        }

        .dz {
            font-size: 0.68rem;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: right;
            color: #32beff;

            white-space: nowrap;
            /* 禁止文本换行 */
            overflow: hidden;
            /* 隐藏超出容器的部分 */
            text-overflow: ellipsis;
            width: 90%;
        }
    }
}
</style>