const state = {
    config: [
      {
        name: "老人信息",
        key: "lrxx",
        component: () => import('@/views/homePage/components/jbxx.vue'),
      },
      {
        name: "健康档案",
        key: "jkda",
        component: () => import('@/views/homePage/components/jkda.vue'),
      },
      {
        name: "赡养人",
        key: "syr",
        component: () => import('@/views/homePage/components/syr.vue'),
      },
      // {
      //   name: "社区服务",
      //   key: "sqfw",
      //   component: () => import('@/views/homePage/components/sqfw.vue'),
      // },
      // {
      //   name: "机构养老",
      //   key: "jgyl",
      //   component: () => import('@/views/homePage/components/jgyl.vue'),
      // },
      // {
      //   name: "居家养老",
      //   key: "jjyl",
      //   component: () => import('@/views/homePage/components/jjyl.vue'),
      // },
      // {
      //   name: "助餐",
      //   key: "zc",
      //   component: () => import('@/views/homePage/components/zc.vue'),
      // },
      {
        name: "补贴",
        key: "bt",
        component: () => import('@/views/homePage/components/bt.vue'),
      },
      {
        name: "一人一码",
        key: "yrym",
        component: () => import('@/views/homePage/components/yrym.vue'),
      },
   
    ]
}
export default {
    namespaced: true,
    state,
  };