import request from '@/utils/request'

export function getDataList(data) {
  return request({
    url: '/api/livingProjectDict/getDataList.json',
    method: 'post',
    data
  })
}
export function settleAccounts(data) {
    return request({
      url: '/api/livingContract/settleAccounts.json',
      method: 'post',
      data
    })
  }
  export function getList(data) {
    return request({
      url: '/api/livingBatch/getList.json',
      method: 'get',
      params:data
    })
  }
  export function livingBatchgetDataList(data) {
    return request({
      url: '/api/livingBatch/getDataList.json',
      method: 'post',
      data
    })
  }
  export function saveData(data) {
    return request({
      url: '/api/livingRuleConfig/saveData.json',
      method: 'post',
      data
    })
  }
  export function getNewData(data) {
    return request({
      url: '/api/livingRuleConfig/getNewData.json',
      method: 'post',
      data
    })
  }
  export function getAllList(data) {
    return request({
      url: '/api/sysRole/getAllList.json',
      method: 'get',
      params: data
    })
  }
  export function livingModulesaveData(data) {
    return request({
      url: '/api/livingModule/saveData.json',
      method: 'post',
      data
    })
  }
  export function livingModulegetDataList(data) {
    return request({
      url: '/api/livingModule/getDataList.json',
      method: 'post',
      data
    })
  }
  export function livingBatchsaveData(data) {
    return request({
      url: '/api/livingBatch/saveData.json',
      method: 'post',
      data
    })
  }
  export function livingBatchgetList(data) {
    return request({
      url: '/api/livingBatch/getList.json',
      method: 'get',
      params: data
    })
  }
  export function livingPackagegetDataList(data) {
    return request({
      url: '/api/livingPackage/getDataList.json',
      method: 'post',
     data
    })
  }
  export function organization(data) {
    return request({
      url: '/api/organization/getList.json',
      method: 'post',
     data
    })
  }
  export function livingPackagesaveData(data) {
    return request({
      url: '/api/livingPackage/saveData.json',
      method: 'post',
     data
    })
  }
  export function deleteData(data) {
    return request({
      url: '/api/livingPackage/deleteData.json',
      method: 'post',
     data
    })
  }
  export function deletelivingetDataList(data) {
    return request({
      url: '/api/livingProject/getDataList.json',
      method: 'post',
     data
    })
  }
  export function livingContractGetDataList(data) {
    return request({
      url: '/api/livingContract/getDataList.json',
      method: 'post',
     data
    })
  }
  export function livingContractSaveData(data) {
    return request({
      url: '/api/livingContract/saveData.json',
      method: 'post',
     data
    })
  }
  export function organizationGetList(data) {
    return request({
      url: '/api/organization/getList.json',
      method: 'post',
     data
    })
  }
  export function sysUserGetListByOrganId(data) {
    return request({
      url: '/api/sysUser//getListByOrganId.json',
      method: 'post',
     data
    })
  }
  export function livingPackagegetList(data) {
    return request({
      url: '/api/livingPackage/getList.json',
      method: 'post',
     data
    })
  }
  export function livingProjectDictgetList(data) {
    return request({
      url: '/api/livingProjectDict/getList.json',
      method: 'post',
     data
    })
  }
  export function livingProjectsaveData(data) {
    return request({
      url: '/api/livingProject/saveData.json',
      method: 'post',
     data
    })
  }
  export function livingProjectdeleteData(data) {
    return request({
      url: 'api/livingProject/deleteData.json',
      method: 'post',
     data
    })
  }
  export function organizationGetList2(data) {
    return request({
      url: '/api/organization/getList/'+ data,
      method: 'get',
    })
  }
  export function livingPackage55(data) {
    return request({
      url: '/api/livingProjectDict/getList.json',
      method: 'post',
      data
    })
  }
  export function livingProjectDictsaveData(data) {
    return request({
      url: '/api/livingProjectDict/saveData.json',
      method: 'post',
      data
    })
  }
  export function livingProjectDictdeleteData(data) {
    return request({
      url: '/api/livingProjectDict/deleteData.json',
      method: 'post',
      data
    })
  }