import * as homePage from '@/api/homePage'
const actions = {
  getTypeDate({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.getTypeDate(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDataInfo({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.getDataInfo(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  saveData({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.saveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  oldUser({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.oldUser(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDataByUserId({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.getDataByUserId(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  supporterSave({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.supporterSave(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteData({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.deleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffet({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffet(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetCapitalGetDataList({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetCapitalGetDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetCapitalGetcollect({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetCapitalGetcollect(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetDishesSaveData({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetDishesSaveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetDishesGetDataList({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetDishesGetDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetDishesTypeGetDataList({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetDishesTypeGetDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetDishesTypeSaveData({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetDishesTypeSaveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetDishesTypeGetList({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetDishesTypeGetList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetDishesSaveData2({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetDishesSaveData2(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetDishesDeleteData({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetDishesDeleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetOrderGetDataList({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetOrderGetDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetOrderGetcollect({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetOrderGetcollect(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetGetDataList({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetGetDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetSaveData({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetSaveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetDataCountGetMonthCount({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetDataCountGetMonthCount(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  buffetDataCountGetCount({ }, parm) {
    return new Promise((resolve, reject) => {
      homePage.buffetDataCountGetCount(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
export default {
  namespaced: true,
  actions
}