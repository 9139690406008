import * as jbsz from '@/api/jbsz'
const actions = {
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.getDataList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  settleAccounts({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.settleAccounts(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  getList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.getList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingBatchgetDataList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingBatchgetDataList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.saveData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  getNewData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.getNewData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  getAllList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.getAllList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingModulesaveData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingModulesaveData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingModulegetDataList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingModulegetDataList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingBatchsaveData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingBatchsaveData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingBatchgetList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingBatchgetList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingPackagegetDataList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingPackagegetDataList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  organization({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.organization(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingPackagesaveData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingPackagesaveData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.deleteData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  deletelivingetDataList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.deletelivingetDataList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingContractGetDataList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingContractGetDataList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingContractSaveData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingContractSaveData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  organizationGetList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.organizationGetList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  sysUserGetListByOrganId({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.sysUserGetListByOrganId(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingPackagegetList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingPackagegetList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingProjectDictgetList({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingProjectDictgetList(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingProjectsaveData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingProjectsaveData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingProjectdeleteData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingProjectdeleteData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  organizationGetList2({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.organizationGetList2(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingPackage55({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingPackage55(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingProjectDictsaveData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingProjectDictsaveData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
  livingProjectDictdeleteData({}, parm) {
    return new Promise((resolve, reject) => {
        jbsz.livingProjectDictdeleteData(parm).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
  },
}
export default {
  namespaced: true,
  actions
}